import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "72.91666666666666%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABkGr+UsaI/8QAHRAAAQIHAAAAAAAAAAAAAAAAAQIDAAQQERIxMv/aAAgBAQABBQIA23RPMq0DDrWCv//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWf/xAAaEAACAgMAAAAAAAAAAAAAAAAAAREhEEFR/9oACAEBAAY/Am1rLXSZshOj/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERMSFBYVH/2gAIAQEAAT8haoLCmiYp9FW6E02liOGVT6P/2gAMAwEAAgADAAAAECcP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxDGyH//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8QrFf/xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhMVFBYZHB/9oACAEBAAE/EAqNNXA8oi6K4mcyd3PJTcaknP5jFWyGgXvvNukUdE2kfmf/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Band Photo #1",
        "title": "Band Photo #1",
        "src": "/static/bd3a6fb9f6d7888e3067991eff273549/2e753/1bandPhoto.jpg",
        "srcSet": ["/static/bd3a6fb9f6d7888e3067991eff273549/69549/1bandPhoto.jpg 288w", "/static/bd3a6fb9f6d7888e3067991eff273549/473e3/1bandPhoto.jpg 576w", "/static/bd3a6fb9f6d7888e3067991eff273549/2e753/1bandPhoto.jpg 1152w", "/static/bd3a6fb9f6d7888e3067991eff273549/74f4b/1bandPhoto.jpg 1728w", "/static/bd3a6fb9f6d7888e3067991eff273549/350e4/1bandPhoto.jpg 2092w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      